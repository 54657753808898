export const qualificationOptions = [
  { label: 'B.A', value: 'B.A' },
  { label: 'M.A', value: 'M.A' },
  { label: 'B.E', value: 'B.E' },
  { label: 'M.E', value: 'M.E' },
  { label: 'B.Tech', value: 'B.Tech' },
  { label: 'M.Tech', value: 'M.Tech' },
  { label: 'B.Sc', value: 'B.Sc' },
  { label: 'M.Sc', value: 'M.Sc' },
  { label: 'BBA', value: 'BBA' },
  { label: 'MBA', value: 'MBA' },
  { label: 'BCA', value: 'BCA' },
  { label: 'MCA', value: 'MCA' },
  { label: 'P.hd', value: 'P.hd' },
  { label: 'M.Phil', value: 'M.Phil' },
  { label: 'B.Arch', value: 'B.Arch' },
  { label: 'MBBS', value: 'MBBS' },
  { label: 'MD', value: 'MD' },
  { label: 'BDS', value: 'BDS' },
  { label: 'MDS', value: 'MDS' },
  { label: 'BPT', value: 'BPT' },
  { label: 'B.Pharm', value: 'B.Pharm' },
  { label: 'M.Pharm', value: 'M.Pharm' },
  { label: 'B.Com', value: 'B.Com' },
  { label: 'M.Com', value: 'M.Com' },
  { label: 'C.A', value: 'C.A' },
  { label: 'ICWA', value: 'ICWA' },
  { label: 'B.Cs', value: 'B.Cs' },
  { label: '12th', value: '12th' },
  { label: '11th', value: '11th' },
  { label: 'LLB', value: 'LLB' },
];

export const incomeOptions = [
  { label: 'Primary source of Income', value: 'primary' },
  { label: 'Additional source of Income', value: 'additional' },
  { label: 'Pursue your passion', value: 'passion' },
  { label: 'Other', value: 'other' },
];

const knowledgeCustomerOptions = [
  { label: 'Existing Customer', value: 'Existing Customer' },
];

const knowledgeSearchOptions = [
  { label: 'Facebook', value: 'facebook' },
  { label: 'Google', value: 'google' },
  { label: 'Instagram', value: 'Instagram' },
  { label: 'Other Websites ', value: 'Web other' },
  { label: 'Location Search', value: 'Location Search' },
];

const knowledgeApartmentOptions = [
  { label: 'Email', value: 'email' },
  { label: 'Poster', value: 'poster' },
];

const knowledgeEventOptions = [
  { label: 'Event', value: 'event' },
  { label: 'Stall', value: 'stall' },
];
const knowledgePreschoolOptions = [{ label: 'Preschool', value: 'Preschool' }];

const knowledgeOtherOptions = [{ label: 'Others', value: 'others' }];

export const knowledgeOptions = [
  { label: 'Customer Referral', options: knowledgeCustomerOptions },
  { label: 'Web Search', options: knowledgeSearchOptions },
  { label: 'Apartment', options: knowledgeApartmentOptions },
  { label: 'Stall/Event', options: knowledgeEventOptions },
  { label: 'Preschool', options: knowledgePreschoolOptions },
  { label: 'Others', options: knowledgeOtherOptions },
];

export const purposeOptions = [
  { label: 'Donate Books', value: 'Donate Books' },
  { label: 'Lend Books', value: 'Lend Books' },
  { label: 'Both', value: 'Both' },
];

export const timeSlotOptions = [
  { label: '10.00 AM - 12.30 PM', value: '10.00 AM - 12.30 PM' },
  { label: '12.30 PM - 03.00 PM', value: '12.30 PM - 03.00 PM' },
  { label: '03.30 PM - 06.00 PM', value: '03.30 PM - 06.00 PM' },
];

export const apartmentComplexOptions = [
  { label: 'Adarsh Palm Retreat', value: 'Adarsh Palm Retreat' },
  { label: 'DLF Capital Greens', value: 'DLF Capital Greens' },
];

export const paymentOptions = [
  { label: 'Members - Free!!!', value: 'Members' },
  { label: 'Non-Members - For 1 session - Rs. 150. ', value: 'Non-Members' },
];
