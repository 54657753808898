export const faqs = [
  {
    title: 'How many hours of work is required everyday?',
    content: () => (
      <div className="sinambans">
        Anything between 1-2 hrs is required for daily operations, general
        administrations and marketing activities for the library.
      </div>
    ),
    _id: 'f1',
  },
  {
    title: 'What criteria do I need to fulfill to become a Töölö Ambassador?',
    content: () => (
      <div className="sinambans">
        Some of the criteria that Töölö checks for are:
        <ul>
          <li>Love for books</li>
          <li>Educational qualifications</li>
          <li>Basic computer proficiency</li>
          <li>Time commitment</li>
        </ul>
      </div>
    ),
    _id: 'f2',
  },
  {
    title: 'Do I need to buy books for the library?',
    content: () => (
      <div className="sinambans">
        No, you do not need to buy books. Töölö will provide you with a set of
        750 curated books for your library.
      </div>
    ),
    _id: 'f3',
  },
  {
    title: 'Will I be given any training to operate the library software?',
    content: () => (
      <div className="sinambans">
        Yes, complete assistance will be provided during the onboarding process
        for every new Töölö Ambassador. Our Tech team is always available for
        any assistance required.
      </div>
    ),
    _id: 'f4',
  },
  {
    title:
      'Will I receive assistance if I face any issue after onboarding is complete?',
    content: () => (
      <div className="sinambans">
        Definitely. Our training and support begins on day one and never ends.
        We at Töölö are extremely sensitive about the wholesome experience of
        the Ambassadors. Hence we are always available for support to help you
        run your business successfully.
      </div>
    ),
    _id: 'f5',
  },
  {
    title: 'How much can I earn?',
    content: () => (
      <div className="sinambans">
        As a Töölö Ambassador, your earnings will depend upon how many
        subscribers you can get for your library. However, you can earn anywhere
        between Rs.25,000-Rs.45,000 per month from home.
      </div>
    ),
    _id: 'f6',
  },
  {
    title: 'What is the frequency of payouts for Töölö Ambassadors?',
    content: () => (
      <div className="sinambans">
        Payouts are done on a monthly basis. All payouts are released before 5th
        of every month to our Ambassadors.
      </div>
    ),
    _id: 'f7',
  },
  {
    title: 'Do I need to pay anything to become a Töölö Ambassador?',
    content: () => (
      <div className="sinambans">
        For information on pricing, please contact us on{' '}
        <a href="mailto:admin@toolo.in">admin@toolo.in</a> or{' '}
        <a href="https://wa.me/919900608202?text=Hi">+91 9900608202</a>{' '}
        (Whatsapp)
        <p>You will receive an initial set of</p>
        <ul>
          <li>750 Curated Books</li>
          <li>Access to a Library Platform</li>
          <li>Branding Materials</li>
          <li>Growth Marketing Tools</li>
          <li>Technical Support</li>
          <li>
            Ongoing knowledge transfer to help you run your business
            successfully.
          </li>
        </ul>
      </div>
    ),
    _id: 'f8',
  },
];

export const preschoolfaqs = [
  {
    title: 'What is a Töölö-powered Reading Bundle and what is included?',
    content: () => (
      <div className="sinambans">
        Töölö- powered Reading bundle comprises of 3 elements towards building a
        strong foundation in raising readers
        <ol>
          <li>Curated Library</li>
          <li>ReadJoy Program</li>
          <li>Rewards</li>
        </ol>
      </div>
    ),
    _id: 'pf1',
  },
  {
    title:
      'Is Töölö a physical reading book library or online reading library?',
    content: () => (
      <div className="sinambans">
        Töölö’s library is online where one can browse and place order for
        books. Books will be delivered to children to enable physical book
        reading.
      </div>
    ),
    _id: 'pf2',
  },
  {
    title:
      'How many books will we get and how many kids can the book-set cater to?',
    content: () => (
      <div className="sinambans">
        A preschool will receive 500 curated books for the age category of 0-7
        yrs . This set can cater to upto 100 - 150 children.
      </div>
    ),
    _id: 'pf3',
  },
  {
    title: 'What types of books do you provide ?',
    content: () => (
      <div className="sinambans">
        We provide board books , picture books , read it yourself books from
        International & national authors to give you a vast variety of books
        available .
      </div>
    ),
    _id: 'pf4',
  },
  {
    title:
      'How do you decide the combination of books you provide and what is the age group you cater to?',
    content: () => (
      <div className="sinambans">
        We curate books specific to the age category of 0-7 yrs . These books
        are a good mix of popular books , award winning books , International
        authors and National authors carefully curated by experts.
      </div>
    ),
    _id: 'pf5',
  },
  {
    title: 'How much storage space does the library require?',
    content: () => (
      <div className="sinambans">
        The library will require approximately 6 -7 shelf space to house 500
        books .
      </div>
    ),
    _id: 'pf6',
  },
  {
    title: 'How to integrate library into the curriculum?',
    content: () => (
      <div className="sinambans">
        Integrating a library into a preschool curriculum can greatly enhance
        children’s learning experience and foster a love for reading and books
        at an early age and build a lifelong habit of reading. Here are some
        ways by which one incorporate reading as part of the children’s daily
        life
        <p>
          <ol>
            <li>
              Have a library period once a week when children will be expected
              to spend time in the library
            </li>
            <li>
              Incorporate “circle time” and read aloud to children every day{' '}
            </li>
            <li>
              Encourage parent involvement in borrowing and reading books{' '}
            </li>
            <li>Create a cozy reading corner in the school</li>
          </ol>
        </p>
      </div>
    ),
    _id: 'pf7',
  },
  {
    title: 'How often should we upgrade the book collection?',
    content: () => (
      <div className="sinambans">
        500 books is a good set of books for a year . It will be a good idea to
        add some books at the end of each year to enhance and bring newness to
        the library.
      </div>
    ),
    _id: 'pf8',
  },
  {
    title: 'Will I be given any training to operate the library software?',
    content: () => (
      <div className="sinambans">
        Yes , complete training and support will be provided from the Töölö team
        in helping the preschool team to be well acquainted with the Töölö
        library software.
      </div>
    ),
    _id: 'pf9',
  },
  {
    title:
      'Will I receive assistance if I face any issue after onboarding is complete?',
    content: () => (
      <div className="sinambans">
        Yes, absolutely. Team Töölö is always available to address issues and
        concerns even after the onboarding is complete.
      </div>
    ),
    _id: 'pf10',
  },
  {
    title: 'What if we want to discontinue and what happens to the books?',
    content: () => (
      <div className="sinambans">
        The books belong to the preschool . These can continue to stay with you
        as part of your library .
      </div>
    ),
    _id: 'pf11',
  },
];
