import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ImageWithLoading } from 'shared/components';
import { routeConstants } from 'shared/constants';
import HealthNotify from './HealthNotify';

const {
  CONTACT_US,
  FAQ,
  ACCOUNT,
  TERMS,
  REFUND_POLICY,
  PRIVACY_POLICY,
  COMING_SOON,
  RECOMMENDATIONS,
  DONOR_APPLY,
} = routeConstants;

const Footer = () => {
  const authUser = useSelector((state) => state.auth.user);
  return (
    <>
      <div className="clearfix" />
      {!authUser?.community?.hideTooloBrand ? (
        <footer>
          <div className="body-container">
            <hr />
          </div>
          <div className="footer-container">
            <div className="topfootermenu">
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <h4>Information</h4>
                  <ul>
                    <li>
                      <Link to={TERMS.route}>Terms and Conditions</Link>
                    </li>
                    <li>
                      <Link to={REFUND_POLICY.route}>Refund Policy</Link>
                    </li>
                    <li>
                      <Link to={PRIVACY_POLICY.route}>Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3 col-sm-6">
                  <h4>Customer Service</h4>
                  <ul>
                    <li>
                      <Link to={CONTACT_US.route}>Contact Us</Link>
                    </li>
                    <li>
                      <Link to={COMING_SOON.route}>Franchise</Link>
                    </li>
                    {/* <li>
                    <Link to={DELIVERY_POLICY.route}>Delivery Policy</Link>
                  </li> */}
                    {/* <li>
                    <a href="/sitemap.xml" target="_blank">
                      Site Map
                    </a>
                  </li> */}
                  </ul>
                </div>
                <div className="col-md-3 col-sm-6">
                  <h4>General</h4>
                  <ul>
                    <li>
                      <Link to={FAQ.route}>FAQS</Link>
                    </li>
                    <li>
                      <Link to={COMING_SOON.route}>Gallery</Link>
                    </li>
                    <li>
                      <Link to={RECOMMENDATIONS.route}>Recommendations</Link>
                    </li>
                    <li>
                      <Link to={DONOR_APPLY.route}>Donate Books</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3 col-sm-6">
                  <h4>Connect with us</h4>
                  <div className="socialmediaf">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/TooloLibrary/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={require('assets/images/facebook.jpg').default}
                            alt="Facebook"
                            className="img-responsive"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/toololibrary/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={require('assets/images/instagram.jpg').default}
                            alt="Instagram"
                            className="img-responsive"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/@TooloLibrary"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={require('assets/images/youtube.png').default}
                            alt="Youtube"
                            className="img-responsive"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/t%C3%B6%C3%B6l%C3%B6/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={require('assets/images/linkedin.png').default}
                            alt="Linkedin"
                            className="img-responsive"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyrightsocial">
              <div className="copyrighttxt">
                © Töölö.in {new Date().getFullYear()}. All Rights Reserved.
              </div>
            </div>
          </div>
          <ImageWithLoading
            src={require('../../../assets/images/icons/whatsapp-l.png').default}
            alt="wp-contact"
            className="wp-contact"
            onClick={() => {
              window.open('https://wa.me/919900608202', '_blank');
            }}
          />
          <HealthNotify />
        </footer>
      ) : (
        <div style={{ height: '70px' }}></div>
      )}
    </>
  );
};

export default Footer;
